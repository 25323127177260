<template>
  <div class="conetnt">
    <div class="mb20"><h1>会员数据</h1></div>

    <div class="icons"></div>

    <div class="member-list">
      <div class="member-item" v-for="(item,index) in memberList" :key="index">
        <div class="flex-base">
          <h1>{{item.count}}</h1>
          <span>个</span>
        </div>
        
        <div style="margin-bottom:10px;">
          <span style="color: #2288fc">{{item.countMonth}} &nbsp;</span>
          <el-image
            style="height: 7px; width: 12px"
            src="https://oss.yanxin7.cn/2023-07-05/599d42e0448252bd4a6a7b008ec47836"
          ></el-image>
          <span>&nbsp;近 30 日</span>
        </div>

        <div class="member-text">{{item.name}}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default{
  props: {
      memberList: {
        type: Array,
        default: [],
      },
  
    },
}
</script>
<style scoped lang="less">
.conetnt {
  padding-top: 57px;
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
h1 {
  font-weight: bolder;
  color: black;
}
.mb20 {
  margin-bottom: 10px;
}
.icons {
  width: 20px;
  height: 4px;
  background: #212121;
  margin-bottom: 50px;
}
.member-list {
  width: 1520px;
  display: flex;
  align-items: center;
}
.member-item {
  margin-right: 12px;
  width: 241px;
  height: 153px;
  background: #f9fcff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 21px;
}
.flex-base {
  display: flex;
  vertical-align: baseline;
  margin-bottom: 10px;
}
span {
  margin-left: 10px;
  line-height: 35px;
  color: #737475;
}
.member-text {
  line-height: 50px;
  text-align: center;
  width: 241px;
  height: 50px;
  background: #fff2c5;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
}
</style>
<template >
  <div class="main-box">
    <div class="main-header">
    <div class="search-for"></div>
    <div class="nav-width">
    </div>
  </div>
    <div class="content-box">
      <div class="left-menu">
        <div
          class="menu-item"
          @click="handMenu(item, index)"
          :class="isActive == index+1 && 'menu-active'"
          v-for="(item, index) in leftMenuList"
          :key="index"
        >
          {{ item.name }}
        </div>
      </div>
      <div class="right-item-list">
        <div
          class="right-item"
          v-for="(item, index) in rightList"
          :key="index + 99"
        >
          <div class="tip"></div>
          <div class="right-title">{{item.name}}</div>
          <el-divider></el-divider>
          <div class="right-text">
            {{ item.label }}
          </div>

          <el-button size="mini"  type="primary"><a target="_blank" :href="item.path">查看详情</a><i class="el-icon-arrow-right el-icon--right"></i></el-button>
        </div>
      </div>
    </div>
    <servicer></servicer>
  </div>
</template>

<script>
import headerMenu from "@/components/header-menu/header-menu.vue";
import visitData from "@/components/visit-data/visit-data.vue";
import memberData from "./components/member-data.vue";
import { getCatory } from"@/api/engineering"
export default {
  components: {
    memberData,
    visitData,
    headerMenu,
  },
  data() {
    return {
      isActive: 1,
      leftMenuList: [
        {
          name: "招投标分析管理系统",
        },
        {
          name: "供应链金融生态体系",
        },
        {
          name: "项目数字化管理体系",
        },
        {
          name: "建筑业增值服务体系",
        },
      ],
      rightList: [{}, {}, {}],
    };
  },

  mounted() {
    console.log('touterxx',this.$route.query.params)

    this.$nextTick(() => {
      this.isActive = this.$route.query.params
      document.title = "生态体系";
    });
    this.getCatory()
  },
  methods: {
    async getCatory() {
      try {
        const { data } = await getCatory({ appid: "pcd87133b3134a7dff" });
        this.leftMenuList = data.filter((item) => item.type == "ecosystem");
        if(this.leftMenuList){
          this.rightList = this.leftMenuList[this.isActive-1].child
        }
      } catch (error) {
        console.log("ee", error);
      }
    },

    handleTabClick() {},
    handMenu(item, index) {
      this.isActive = index+1;
      this.rightList = item.child
    },
  },
};
</script>
<style>
.main-header {
  width: 100%;
  background-image: url("https://oss.yanxin7.cn/2023-07-04/2c735de815fc229cb37801d40cbee557");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  opacity: 1;
}
.main-box {
  box-sizing: border-box;
  overflow: hidden;
}
.content-box {
  padding-top: 50px;
  box-sizing: border-box;
  width: 1510px;
  display: flex;
  margin-left: 50%;
  transform: translateX(-50%);
  padding-bottom: 30px;
}
.left-menu {
  display: flex;
  flex-direction: column;
}
.menu-item {
  height: 60px;
  line-height: 60px;
  padding-left: 40px;
  cursor: pointer;
  margin-right: 100px;
}
.menu-active {
  border-left: 2px solid #2288fc;
  color: #2288fc;
}
.right-item-list {
  width: 1200px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.right-item {
  margin-bottom: 20px;
  width: 590px;
  height: 402px;
  background: #ffffff;
  box-shadow: 3px 3px 16px 1px rgba(61, 145, 255, 0.16);
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  border: 1px solid #eff2f8;
  box-sizing: border-box;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: start;
}
.tip {
  width: 36px;
  height: 8px;
  background-color: #2288fc;
  margin-bottom: 30px;
}
.right-title {
  font-size: 24px;
  font-weight: bold;
}
.right-text {
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: pre-wrap;
  text-align: start;
  color: #212121;
  line-height: 34px;
  font-size: 16px;
  margin-bottom: 40px;
}
a{
  text-decoration: none;
  color: white;
}

.search-for {
  width: 100%;
  height: 330px !important;
  color: white;
  text-align: center;
}
</style>

